<template>
  <div class="mt-3 text-grey1 mx-auto px-5 py-8 sm:w-80 lg:max-w-2xl">
    <template v-if="isLeader || accountPlan != 3">
      <div>
        Add colleagues to a
        <a-link
          segmentName="Learn team pro"
          href="https://help.meetric.app/en/articles/5507398-meetric-pro-for-your-team"
          >Team Pro</a-link
        >
        subscription (one email address on each line):
      </div>
      <div class="flex mt-2 mb-1">
        <textarea
          rows="5"
          class="
            w-full
            px-3
            py-1
            rounded
            bg-grey6
            text-grey2
            font-normal
            select-text select-all
          "
          v-model="subscribers"
        ></textarea>
      </div>
      <div
        class="text-alerterror text-sm"
        v-if="
          subscribers != '' &&
          lines.length > 0 &&
          subscriberMailList.length + 1 < lines.length
        "
      >
        Invalid emails, already members and duplicated rows will be ignored!
      </div>
      <div class="" v-if="accountPlan != 3">
        PS: you're automatically included
      </div>
      <div>
        <m-button
          class="mb-4 mt-3"
          button-style="btn-primary"
          segmentName="Team subscription"
          icon="💎"
          @click="initSubscribe"
          :disabled="subscriberMailList.length === 0"
          :class="{
            'opacity-50': subscriberMailList.length === 0,
          }"
          :pending="loadSubscribe"
        >
          Subscribe {{ subscriberMailList.length }} members
        </m-button>
      </div>
    </template>

    <template v-if="!loadMembers">
      <div v-if="accountPlan == 3 && isLeader" class="my-3 font-semibold">
        {{ members.length }} members in current Team Pro subscription (contact
        us to remove members or cancel subscription)
      </div>
      <div v-else-if="accountPlan == 3 && !isLeader" class="my-3 font-semibold">
        {{ members.length }} members in current Team Pro subscription (contact
        your team admin <span class="italic">{{ teamLeader }}</span> to
        add/remove members)
      </div>
      <div v-else-if="accountPlan != 3" class="my-3 font-semibold">
        {{ members.length }} members in current Team Pro subscription
      </div>

      <div v-if="members.length > 0">
        <template v-for="member in members">
          <div :key="member.username">
            <div>
              <span :title="member.username" data-recording-sensitive>
                {{ member.username }}
              </span>
              <!-- <div
              @click="removeMember(member.username)"
              class="cursor-pointer text-grey3 hover:text-alerterror ml-1"
            >
              ✗
            </div> -->
            </div>
            <!-- <div class="pl-2 ptext-right">
            <m-checkbox
              v-model="recipient.sendRecap"
            ></m-checkbox>
          </div> -->
          </div>
        </template>
      </div>
      <div v-else>
        <template
          ><div></div>
          <span class="italic">None</span>
        </template>
      </div>
    </template>
    <template v-if="colleagues.length > 0">
      <hr class="w-full my-5 border-grey6" />
      <div class="my-3 font-semibold">
        {{ colleagues.length }} colleagues are already Meetric users:
      </div>
      <template v-for="col in colleagues">
        <div :key="col">
          <div>
            <span :title="col" data-recording-sensitive>
              {{ col }}
            </span>
          </div>
        </div>
      </template>
    </template>

    <div class="mt-5 text-alerterror text-center">{{ errorText }}</div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
// import MCheckbox from '@/components/UI/MCheckbox';

import { subscribe, getTeam } from '@/api/Cherry';
export default {
  name: 'TeamSubscriptionPage',
  components: {
    MButton,
    ALink,
  },
  data() {
    return {
      members: [],
      subscribers: '',
      loadSubscribe: false,
      addSubscriberInput: '',
      errorText: '',
      loadMembers: false,
      isLeader: false,
      teamLeader: '',
      colleagues: [],
    };
  },
  computed: {
    subscriberMailList() {
      let list = this.subscribers.split('\n');
      let valids = list
        .filter((f) => this.isValidEmail(f))
        .filter((f) => f != this.username)
        .filter((f) => f != '')
        .filter((f) => this.members.filter((m) => m.username == f).length == 0);
      let noDuplicateValids = [...new Set(valids)];
      var result = noDuplicateValids.map((mail) => ({
        username: mail,
      }));

      return result;
    },
    lines() {
      return this.subscribers.split('\n');
    },
    accountPlan() {
      return this.$store.getters['plan'];
    },
    username() {
      return this.$gAuth.basicProfile.getEmail();
    },
  },
  mounted() {
    this.getTeam();
    this.getColleagues();
  },
  created() {
    let q = this.$route.query.q;
    if (q) {
      this.$notification(
        '🎉  ' + q + ' member(s) added to the Meetric Pro Team plan',
        {
          closable: true,
          type: 'validation',
        }
      );
    }
  },
  methods: {
    isValidEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
        email
      );
    },
    getColleagues: function () {
      this.colleagues = this.$store.getters['colleagues'].colleagues;
    },
    initSubscribe() {
      this.loadSubscribe = true;
      subscribe(this.subscriberMailList, true)
        .then((r) => {
          if (r.error) {
            this.$snack(r.error.message);
            this.loadSubscribe = false;
          } else {
            if (r.URL && r.CurrentPlan == 0) {
              window.location.href = r.URL;
            } else {
              if (this.accountPlan == 2) {
                let count = this.subscriberMailList.length;
                let hr = window.location.pathname + '?q=' + count;
                console.log(hr, count);
                window.location.href = hr;
              } else {
                this.$notification(
                  '🎉  ' +
                    this.subscriberMailList.length +
                    ' member(s) added to the Meetric Pro Team plan',
                  {
                    closable: true,
                    type: 'validation',
                  }
                );
                this.getTeam();
                this.subscribers = '';
                this.loadSubscribe = false;
              }
            }
          }
        })
        .catch(() => {
          this.loadSubscribe = false;
          console.error('Subscribe initialisation failed');
        });
    },
    getTeam() {
      this.loadMembers = true;
      if (this.accountPlan == 3) {
        getTeam()
          .then((r) => {
            this.members = [];
            if (this.accountPlan == 3) {
              this.isLeader = r.team_lead == this.username;
              this.teamLeader = r.team_lead;
              if (this.isLeader) {
                this.members.push({ username: this.username });
              } else {
                this.members.push({ username: r.team_lead });
              }
              if (r.team_members && r.team_members.length > 0) {
                this.members = this.members.concat(r.team_members);
              }
            }
          })
          .then(() => {
            this.loadMembers = false;
          });
      }
    },
  },
};
</script>
<style scoped></style>
